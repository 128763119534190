/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useState, useEffect, useRef} from 'react';
import http from 'utilities/http';
import {getCookie, removeCookie, setCookie} from 'framework/libs/cookie';
import {
    CART,
    TOKEN_CHAT,
    TOKEN_CONGDONG,
    TOKEN_SHOP,
} from 'framework/store/constants';
import {isEmpty, isArray, isNumber} from 'lodash';
import {useCart} from 'framework/store';
import SearchArea from 'components/shop/searchShop';
import {Loader} from 'components/loader';
import {Button, Modal} from 'react-bootstrap';
import SeeMore from 'components/seeMore';
import {toast} from 'react-toastify';
import jsCookie from 'js-cookie';
import {SLUG_LIST} from 'utilities/constants';
import SliderAd from 'components/chuyen-gia/slider';
import dayjs from 'dayjs';
import Link from 'next/link';
import {getShopCategories} from 'utilities/api';
import {selectModel} from 'models/inputModel';

export default function HeaderSiteMini() {
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const {route} = router;
    const [stateStore, actions] = useCart();
    const {slug} = router.query;
    const [state, _setState] = useState({
        showSearch: false,
        showNoti: false,
        showSetting: false,
        showCreate: false,
        menusCongDong: SLUG_LIST,
        notify: [],
        countNotify: 0,
    });
    const setState = (_state = {}) => {
        _setState({
            ...state,
            ..._state,
        });
    };
    const fetchHomeData = async (slug,route="") => {
        try {
            let slugQuery = slug;
            if(isArray(slug) && typeof slug[0] != "undefined"){
                slugQuery = slug[0];
            }
            const res = await http(`site-mini/show/${slugQuery}`, {}, {}, 'v2');
            if (res?.code === 200) {
                actions.setInformationSite(res.data);
            } else {
                console.error('Request failed with status code:', res?.code);
            }
        } catch (error) {
            console.error('Error occurred while fetching data:', error);
            // Xử lý lỗi ở đây nếu cần
        }
    };
    useEffect(async () => {
        if (slug) {
            await fetchHomeData(slug,route);
        }
    }, [slug, route]);
    return (
        <>
            <Loader loading={loading}/>
            <div id="header-switch-version">
                <div className="max-1320px switch-version"></div>
            </div>
            <header className={'header'+' '+(stateStore.informationSite.slug)}>
                <div className="headerTop headerTopMini">
                    <div className="wrap">
                        <div className="headerTop-dflex">
                            <div className="headerInfo">
                                <div className="logo sp-only" style={{width: '200px'}}>
                                    <a href="/" className="site-mini">
                                        {stateStore.informationSite.name}
                                    </a>
                                </div>
                                <div className="logo" style={{width: '200px'}}>
                                    <a href={`/sites/${stateStore.informationSite.slug}`} className="site-mini">
                                        {stateStore.informationSite.slug === 'bosch' && (
                                            <img src={stateStore.informationSite.logo}/>
                                        )}
                                        {stateStore.informationSite.slug !== 'bosch' && (stateStore.informationSite.name)}
                                    </a>
                                </div>
                            </div>
                            <div className="headerControl">
                                <SearchArea state={state} setState={setState} isFull={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
