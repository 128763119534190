/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import http from 'utilities/http';
import { getCookie, removeCookie, setCookie } from 'framework/libs/cookie';
import {
    CART,
    TOKEN_CHAT,
    TOKEN_CONGDONG,
    TOKEN_SHOP,
} from 'framework/store/constants';
import { isEmpty, isArray, isNumber } from 'lodash';
import { useCart } from 'framework/store';
import SearchArea from 'components/shop/searchShop';
import { Loader } from 'components/loader';
import { Button, Modal } from 'react-bootstrap';
import SeeMore from 'components/seeMore';
import { toast } from 'react-toastify';
import jsCookie from 'js-cookie';
import { SLUG_LIST } from 'utilities/constants';
import SliderAd from 'components/chuyen-gia/slider';
import dayjs from 'dayjs';
import Link from 'next/link';
import { getShopCategories } from 'utilities/api';
import { selectModel } from 'models/inputModel';

const masterMenuData = [
    {
        name: 'CỘNG ĐỒNG',
        url: '/',
    },
    {
        name: 'SHOP',
        url: '/shop',
    },
    {
        name: 'CHUYÊN GIA',
        url: '/chuyen-gia',
    },
];

const shopMenuData = [
    {
        name: 'Trang chủ',
        url: '/shop',
    },
    {
        name: 'Danh mục',
        url: '/shop/danh-muc',
    },
    {
        name: 'Flashsale',
        url: '/shop/flash-sale',
    },
    {
        name: 'Deal Hot',
        url: '/shop/deal-hot',
    },
    {
        name: 'Mã giảm giá',
        url: '/shop/coupon',
    },
    {
        name: 'Happynest Map',
        url: '/happynest-map',
    },
];
export default function headerHome() {
    const per_page_noti = 10;
    const [stateStore, actions] = useCart();
    const [user, setUser] = useState({});
    const router = useRouter();
    console.log(router);
    const [state, _setState] = useState({
        showSearch: false,
        showNoti: false,
        showSetting: false,
        showCreate: false,
        menusCongDong: SLUG_LIST,
        notify: [],
        countNotify: 0,
    });
    const setState = (_state = {}) => {
        _setState({
            ...state,
            ..._state,
        });
    };
    useEffect(() => {
        if (!isEmpty(stateStore.search)) {
            setState({ showSearch: true });
        }
    }, [stateStore.search]);
    useEffect(() => {
        const fetchHeader = async () => {
            try {
                const valueState = {};
                if (!isEmpty(getCookie(TOKEN_CONGDONG))) {
                    const response = await Promise.all([
                        http(
                            'user',
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                        ),
                        http(
                            `user/notifications?per_page=${per_page_noti}`,
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                            'v2',
                        ),
                        http(
                            'user/notifications/unread-count',
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                            'v2',
                        ),
                    ]);
                    if (
                        response[0] &&
                        response[0].is_professional &&
                        response[0].id
                    ) {
                        const res = await http(
                            `community/professional/${response[0].id}`,
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                            'v2',
                        );
                        response[0] = {
                            ...response[0],
                            ...(res?.data || {}),
                        };
                    }
                    actions.setProfileCommunity(response[0]);
                    setUser(response[0]);
                    valueState.notify = response[1]?.data || [];
                    setSeeMore([
                        per_page_noti,
                        response[1]?.meta?.pagination?.total || 0,
                    ]);
                    if (response[2]?.data > 0) {
                        valueState.countNotify = response[2].data;
                    }
                }
                if (!isEmpty(getCookie(TOKEN_SHOP))) {
                    const res = await Promise.all([
                        http(
                            '/rest/default/V1/customers/me',
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_SHOP) },
                        ),
                        http(
                            '/rest/V1/carts/mine',
                            {},
                            { token: 'Bearer ' + getCookie(TOKEN_SHOP) },
                        ),
                    ]);
                    if (
                        (res[0]?.message || '').startsWith("The consumer isn't")
                    ) {
                        console.log('Session end');
                        removeCookie(TOKEN_CHAT);
                        removeCookie(TOKEN_CONGDONG);
                        removeCookie(TOKEN_SHOP);
                        removeCookie(CART);
                        actions.setProfileCommunity(null);
                        setState({ showSetting: false });
                        actions.cart(null);
                        setLoading(false);
                        router.push('/');
                    }
                    actions.setProfileShop(res[0]);
                    actions.cart(res[1]);
                }
                // else {
                //     const response = await http(
                //         `/rest/V1/guest-carts/${getCookie(CART)}`,
                //         {},
                //         {
                //             token: 'default'
                //         }
                //     );
                //     actions.cart(response);
                // }
                const res = await http(
                    `community/categories?per_page=20`,
                    {},
                    { token: 'default' },
                    'v2',
                );
                if (res?.code == 200) {
                    valueState.menusCongDong = res.data;
                }
                actions.set('communityCategories', res.data || []);
                setState(valueState);
            } catch (e) {
                console.log(e.message);
            }
        };
        fetchHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchVendorList = async () => {
            const res = await http(
                `/rest/V1/vendor/list`,
                { size: 0 },
                { token: 'default' },
            );
            actions.setVendorList(res?.items || []);
        };
        fetchVendorList();

        const fetchWishList = async () => {
            if (isEmpty(getCookie(TOKEN_SHOP))) return;
            const res = await http(
                '/rest/V1/wishlist/allItems',
                {},
                { token: 'Bearer ' + getCookie(TOKEN_SHOP) },
            );
            if (isArray(res)) {
                actions.setWishListAll(res || []);
            }
        };
        fetchWishList();

        const fetchSavedCoupon = async () => {
            if (isEmpty(getCookie(TOKEN_SHOP))) return;
            const res = await http(
                '/rest/V1/customer/coupons',
                { size: 0 },
                { token: `Bearer ${getCookie(TOKEN_SHOP)}` },
            );
            if (isArray(res)) {
                actions.setSavedCoupon(res || []);
            }
        };
        fetchSavedCoupon();
    }, []);

    const readNoti = async (id) => {
        const thisNoti = state.notify.find((x) => x.id == id);
        if (thisNoti.is_read) return;

        const read = await http(
            `user/notifications/${id}/read`,
            {},
            {
                token: 'Bearer ' + getCookie(TOKEN_CONGDONG),
                method: 'POST',
                body: {
                    device: 'web',
                },
            },
            'v2',
        );
        if (read?.code == 200) {
            thisNoti.is_read = true;
            setState({ notify: state.notify });

            const readCount = await http(
                'user/notifications/unread-count',
                {},
                { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                'v2',
            );
            if (readCount?.data > 0) {
                setState({ countNotify: readCount?.data });
            }
        }
    };
    // xem them noti
    const [seeMore, setSeeMore] = useState([0, 0]);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (currentPage > 1) {
            const fetchMoreNoti = async () => {
                const result = await http(
                    `user/notifications?per_page=${per_page_noti}&page=${currentPage}`,
                    {},
                    { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
                    'v2',
                );
                result.code === 200
                    ? setState({ notify: state.notify.concat(result?.data) })
                    : '';
            };
            fetchMoreNoti();
        }
    }, [currentPage]);

    const toLogin = () => {
        actions.setLastRoute(router.asPath);
        window.location.href= "/login";
        //router.push('/login');
    };

    const toRegister = () => {
        window.location.href= "/register";
        //router.push('/register');
    };

    const genStateLogin = () => {
        const isMagazine = router.asPath.includes('happynest-story');
        if (
            !stateStore.profileCommunity ||
            stateStore?.profileCommunity?.code != 200
        ) {
            return (
                <div
                    className={
                        'headerLog ' + (isMagazine ? 'headerLog-emagazine' : '')
                    }
                >
                    <ul>
                        {!isMagazine && (
                            <li
                                className="headerLog-cart"
                                onClick={() =>
                                    toast.info(
                                        'Bạn cần đăng nhập để truy cập giỏ hàng',
                                    )
                                }
                            >
                                <a>
                                    <img src="/images/icon-cart.svg" alt="" />
                                    <span
                                        className={
                                            stateStore?.cart?.items_qty > 9
                                                ? 'two'
                                                : ''
                                        }
                                    >
                                        {stateStore?.cart?.items_qty || 0}
                                    </span>
                                </a>
                            </li>
                        )}
                        <li className="headerLog-info">
                            <a onClick={toLogin} className="cursor-pointer">
                                Đăng nhập
                            </a>
                        </li>
                        <li className="headerLog-info">
                            <a onClick={toRegister} className="cursor-pointer">
                                Đăng ký
                            </a>
                        </li>
                    </ul>
                </div>
            );
        }
        return (
            <div
                className={
                    'headerLog ' + (isMagazine ? 'headerLog-emagazine' : '')
                }
            >
                <ul>
                    {!isMagazine && (
                        <li className="headerLog-cart">
                            <a href={'/shop/cart'}>
                                <img src="/images/icon-cart.svg" alt="" />
                                <span
                                    className={
                                        stateStore?.cart?.items_qty > 9
                                            ? 'two'
                                            : ''
                                    }
                                >
                                    {stateStore?.cart?.items_qty || 0}
                                </span>
                            </a>
                        </li>
                    )}
                    {!isMagazine && (
                        <li className="headerLog-notification">
                            <a
                                className="cursor-pointer"
                                onClick={() => setState({ showNoti: true })}
                            >
                                <img
                                    src={
                                        state.showNoti
                                            ? '/images/icon-notification-show.svg'
                                            : '/images/icon-notification01.svg'
                                    }
                                    alt=""
                                />
                                <span
                                    className={
                                        state.countNotify > 9 ||
                                        String(state.countNotify).length > 1
                                            ? 'two'
                                            : ''
                                    }
                                >
                                    {state.countNotify}
                                </span>
                            </a>
                            <div
                                className={
                                    `dropdown-menu dropdown-menu-right dropdown--notification ` +
                                    (state.showNoti ? 'd-block' : 'd-none')
                                }
                            >
                                <div className="notification">
                                    <div className="notification-group">
                                        <div className="notification-title">
                                            <h3 className="notification-tlt">
                                                Thông báo của bạn
                                            </h3>
                                            <a
                                                href="/tai-khoan/thong-bao"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    setState({
                                                        showNoti: false,
                                                    })
                                                }
                                            >
                                                Xem tất cả
                                            </a>
                                        </div>
                                        <div className="notification-lst">
                                            {!isEmpty(state.notify) && (
                                                <ul>
                                                    {state.notify.map(
                                                        (item, index) => {
                                                            let avatar =
                                                                item.data
                                                                    ?.user_avatar;
                                                            if (
                                                                item.data
                                                                    ?.user_avatar &&
                                                                item.data?.user_avatar.startsWith(
                                                                    '/',
                                                                )
                                                            ) {
                                                                avatar =
                                                                    'https://happynest.vn' +
                                                                    item.data
                                                                        ?.user_avatar;
                                                            }
                                                            if (
                                                                item.data
                                                                    .post_type ===
                                                                    'membership_point' &&
                                                                item.data.message.startsWith(
                                                                    'Chúc mừng',
                                                                )
                                                            ) {
                                                                item.data.post_url =
                                                                    '/tai-khoan/ho-so?t=hang-thanh-vien';
                                                            }
                                                            if (
                                                                item.data
                                                                    .post_type ===
                                                                'approval_account'
                                                            ) {
                                                                item.data.post_url =
                                                                    '/tai-khoan/ho-so';
                                                            }
                                                            if (
                                                                item.data
                                                                    .post_type ===
                                                                    'membership_point' &&
                                                                item.data.message.startsWith(
                                                                    'Bài viết',
                                                                )
                                                            ) {
                                                                item.data.post_url =
                                                                    '/tai-khoan/ho-so?t=lich-su-diem';
                                                            }
                                                            if (
                                                                item.data
                                                                    .post_type ===
                                                                'consultant'
                                                            ) {
                                                                item.data.post_url =
                                                                    '/tai-khoan/don-tu-van';
                                                            }
                                                            if (
                                                                item.data
                                                                    .post_type ===
                                                                    'article' ||
                                                                item.data
                                                                    .post_type ===
                                                                    'consultant'
                                                            ) {
                                                                avatar =
                                                                    '/images/common/Logo.svg';
                                                            }
                                                            const time = dayjs(
                                                                item.data
                                                                    ?.post_time *
                                                                    1000,
                                                            );
                                                            const diffHour =
                                                                dayjs().diff(
                                                                    time,
                                                                    'hour',
                                                                );
                                                            let diffMinute =
                                                                null;

                                                            if (diffHour == 0) {
                                                                diffMinute =
                                                                    dayjs().diff(
                                                                        time,
                                                                        'minute',
                                                                    );
                                                            }

                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className={
                                                                        item.is_read
                                                                            ? ''
                                                                            : 'active'
                                                                    }
                                                                >
                                                                    <a
                                                                        className="cursor-pointer"
                                                                        href={
                                                                            item
                                                                                .data
                                                                                ?.post_url ||
                                                                            '#'
                                                                        }
                                                                        onClick={() =>
                                                                            readNoti(
                                                                                item.id,
                                                                                item
                                                                                    .data
                                                                                    ?.post_url,
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className=" notification-thumb">
                                                                            <div className=" notification-avatar">
                                                                                <img
                                                                                    src={
                                                                                        avatar
                                                                                    }
                                                                                    width={
                                                                                        44
                                                                                    }
                                                                                    height={
                                                                                        44
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className=" notification-item">
                                                                                <img
                                                                                    src="/images/icon-notification-photo.svg"
                                                                                    width={
                                                                                        18
                                                                                    }
                                                                                    height={
                                                                                        18
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className=" notification-content">
                                                                            <p
                                                                                className="text"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        item
                                                                                            .data
                                                                                            ?.message ||
                                                                                        '',
                                                                                }}
                                                                            ></p>
                                                                            {diffMinute && (
                                                                                <p
                                                                                    className="font-weight-light c-disable"
                                                                                    style={{
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                >
                                                                                    {diffMinute +
                                                                                        ' phút'}
                                                                                </p>
                                                                            )}
                                                                            {!diffMinute && (
                                                                                <p
                                                                                    className="font-weight-light c-disable"
                                                                                    style={{
                                                                                        fontSize: 12,
                                                                                    }}
                                                                                >
                                                                                    {diffHour >
                                                                                        0 &&
                                                                                    diffHour <
                                                                                        24
                                                                                        ? diffHour +
                                                                                          ' giờ'
                                                                                        : time.format(
                                                                                            'DD/MM/YYYY HH:mm',
                                                                                        )}
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                        <SeeMore
                                            page={seeMore}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                    {!isMagazine && (
                        <li className=" headerLog-bookmark">
                            <a href={`/tai-khoan/ho-so?t=so-luu-tru`}>
                                <Image
                                    width={18}
                                    height={21}
                                    src="/images/icon-bookmark01.svg"
                                    alt=""
                                />
                            </a>
                        </li>
                    )}
                    <li className=" headerLog-user">
                        <a
                            style={{ cursor: 'pointer' }}
                            data-toggle=" dropdown"
                            onClick={() => setState({ showSetting: true })}
                        >
                            <div className=" thumb-user">
                                <Image
                                    width={32}
                                    height={32}
                                    src={
                                        stateStore?.profileCommunity?.avatar ||
                                        '/images/icon-bookmark01.svg'
                                    }
                                    alt=""
                                />
                            </div>
                            <div className=" mt-3 ml-1">
                                <Image
                                    width={8}
                                    height={8}
                                    src="/images/icon-arrow-user.svg"
                                    alt=""
                                />
                            </div>
                        </a>

                        <div
                            className={`dropdown-menu dropdown-menu-right navUser ${
                                state.showSetting ? 'd-block' : 'd-none'
                            }`}
                            style={{ zIndex: 1002, overflow: 'hidden' }}
                        >
                            <ul>
                                <li
                                    onClick={() =>
                                        setState({ showSetting: false })
                                    }
                                >
                                    <a href={`/tai-khoan/ho-so`}>Hồ sơ</a>
                                </li>
                                <li
                                    onClick={() =>
                                        setState({ showSetting: false })
                                    }
                                >
                                    <a href="/tai-khoan/mua-sam">Mua sắm</a>
                                </li>
                                {/* <li onClick={() => setState({showSetting: false})}>
                               <a href="/tai-khoan/su-kien">Sự kiện</a>
                            </li> */}
                                <li
                                    onClick={() =>
                                        setState({ showSetting: false })
                                    }
                                >
                                    <a href="/tai-khoan/cai-dat">Cài đặt</a>
                                </li>
                                <li
                                    onClick={() => {
                                        setLogoutState(true);
                                        setState({ showSetting: false });
                                    }}
                                >
                                    <a style={{ cursor: 'pointer' }}>
                                        Đăng xuất
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        );
    };

    const logout = async () => {
        const res = await http(
            'auth/logout',
            {},
            {
                method: 'POST',
                token: 'Bearer ' + getCookie(TOKEN_CONGDONG),
            },
            'v2',
        );
        removeCookie(TOKEN_CHAT);
        removeCookie(TOKEN_CONGDONG);
        removeCookie(TOKEN_SHOP);
        removeCookie(CART);
        actions.setProfileCommunity(null);
        setState({ showSetting: false });
        actions.cart(null);
        setLoading(false);
        router.push('/');
    };
    const [logoutState, setLogoutState] = useState(false);
    const modalLogout = () => {
        return (
            <Modal
                show={logoutState}
                onHide={() => {
                    setLogoutState(false);
                }}
                role="document"
                className=""
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className="modal-title">Bạn có muốn đăng xuất?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        style={{ width: '50%' }}
                        onClick={() => {
                            setLogoutState(false);
                        }}
                    >
                        Không
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ width: '50%' }}
                        onClick={() => {
                            setLoading(true);
                            setLogoutState(false);
                            logout();
                        }}
                    >
                        Có
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const taiKhoanMenuData = [
        {
            name: 'Hồ sơ',
            url: `/tai-khoan/ho-so`,
        },
        {
            name: 'Mua sắm',
            url: '/tai-khoan/mua-sam',
        },
        {
            name: 'Đơn tư vấn',
            url: '/tai-khoan/don-tu-van',
        },
        {
            name: 'Cài đặt',
            url: '/tai-khoan/cai-dat',
        },
    ];

    const genMenuFunc = (menu, index) => {
        let activeClass = '';
        if (
            menu.name === 'CỘNG ĐỒNG' &&
            (router.pathname === '/' ||
                state.menusCongDong.some(
                    (menu) =>
                        router.asPath.indexOf(
                            '/' +
                                (menu.slug == 'kho-anh' ? 'album' : menu.slug),
                        ) == 0,
                ))
        ) {
            activeClass = ' active';
        }
        if (
            menu.url === '/shop/danh-muc' &&
            router.pathname === '/shop/[...category]'
        ) {
            activeClass = ' active';
        }
        if (menu.url === '/shop' && router.pathname === '/shop') {
            activeClass = ' active';
        }
        if (menu.name === 'SHOP' && router.pathname.indexOf(menu.url) === 0) {
            activeClass = ' active';
        }
        if (
            menu.name === 'CHUYÊN GIA' &&
            (router.pathname.indexOf(menu.url) === 0 ||
                router.pathname.indexOf('/thanh-vien/u') === 0)
        ) {
            activeClass = ' active';
        }
        if (
            menu.url !== '/' &&
            menu.url !== '/shop/danh-muc' &&
            menu.url !== '/shop' &&
            router.pathname.indexOf(menu.url) === 0
        ) {
            activeClass = ' active';
        }

        if (menu.name === 'CHUYÊN GIA' || menu.name === 'CỘNG ĐỒNG') {
            return (
                <li key={index}>
                    <a href={'' + menu.url}>
                        <a className={activeClass}>{menu.name}</a>
                    </a>
                </li>
            );
        }
        if (menu.name === 'SHOP') {
            return (
                <li key={index}>
                    <a href={'' + menu.url}>
                        <a className={activeClass}>{menu.name}</a>
                    </a>
                </li>
            );
        }
        const routerArr = router.pathname.split('/');
        return (
            <li key={index}>
                {/* className={spnoneClass}> */}
                {routerArr[1] === 'tai-khoan' ? (
                    <a
                        href={menu.url}
                        className={
                            menu.url.split('/').reverse()[0] ===
                            routerArr.reverse()[0]
                                ? ' active'
                                : ''
                        }
                        style={{ padding: '0' }}
                    >
                        {menu.name}
                    </a>
                ) : (
                    <a href={menu.url} className={activeClass}>
                        {menu.name}
                    </a>
                )}
            </li>
        );
    };
    const genMenuCongDong = (menu) => {
        if (isEmpty(menu)) {
            return null;
        }
        let activeClass = '';
        if (
            menu.slug != '' &&
            router.asPath.includes(
                '/' + (menu.slug == 'kho-anh' ? 'album' : menu.slug),
            )
        ) {
            activeClass = 'active';
        }
        if (menu.slug == '' && router.pathname === '/') {
            activeClass = 'active';
        }

        let slug = menu.slug;
        if (menu.slug == 'kho-anh') {
            slug = 'album';
        }
        if (menu.slug.toLowerCase() == 'emagazine') {
            slug = 'happynest-story';
        }
        const beta = ['kho-360'];
        return (
            <li key={menu.id} className="cong-dong">
                <a
                    href={`/${slug}`}
                    className={activeClass + ' position-relative'}
                >
                    {menu.name}
                    {beta.some((x) => x == slug) && (
                        <div
                            className="position-absolute pc-only"
                            style={{
                                background: '#FF7572',
                                color: '#fff',
                                borderRadius: '4px',
                                right: -20,
                                top: 4,
                                padding: 3,
                                fontSize: 10,
                                lineHeight: '10px',
                            }}
                        >
                            Beta
                        </div>
                    )}
                </a>
            </li>
        );
    };
    const genMasterMenuData = masterMenuData.map(genMenuFunc);
    const genShopMenuData = shopMenuData.map(genMenuFunc);
    const genTaiKhoanMenuData = taiKhoanMenuData.map(genMenuFunc);
    const genCongDongMenuData = [
        {
            id: 0,
            slug: '',
            name: 'Trang chủ',
        },
    ]
        .concat(state.menusCongDong)
        .map(genMenuCongDong);

    const genNav = () => {
        // if (router.pathname === '/'
        //     || router.pathname.includes("/hashtag")
        //     || state.menusCongDong.some(menu => router.asPath.indexOf('/' + (menu.slug == 'kho-anh' ? 'album' : menu.slug)) == 0)
        // ) return genCongDongMenuData;
        if (router.pathname.indexOf('shop') > 0) return genShopMenuData;
        if (router.pathname.indexOf('tai-khoan') > 0)
            return genTaiKhoanMenuData;
    };
    const genBtnEdit = () => {
        return (
            <div
                className=" btnDangBai"
                // onClick={() => router.push('/cong-dong/tao-bai-viet/chuyen-nha')} style={{cursor: 'pointer'}}
            >
                <div
                    style={{ cursor: 'pointer', width: '100%', height: 40 }}
                    onClick={() => {
                        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
                            toast.info(
                                'Bạn cần đăng nhập để viết bài trên Happynest!',
                            );
                            return;
                        }

                        setState({ showCreate: !state.showCreate });
                    }}
                ></div>
                <div
                    className={`dropdown-menu dropdown-menu-right ${
                        state.showCreate ? 'd-block' : 'd-none'
                    }`}
                >
                    <h3 className=" title">Tạo bài viết về</h3>
                    <ul
                        className=" list-post"
                        onClick={() => setState({ showCreate: false })}
                    >
                        <li>
                            <a href="/tao-bai-viet/1/chuyen-nha">
                                <span className=" icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-1.svg"
                                        alt=""
                                    />
                                </span>
                                <div className=" info">
                                    <span>Chuyện nhà</span>
                                    <span className="poin">1000++ điểm</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/tao-bai-viet/1/album">
                                <span className=" icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-2.svg"
                                        alt=""
                                    />
                                </span>
                                <div className=" info">
                                    <span>Kho ảnh</span>
                                    <span className="poin">500++ điểm</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/tao-bai-viet/1/review-san-pham">
                                <span className=" icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-3.svg"
                                        alt=""
                                    />
                                </span>
                                <div className=" info">
                                    <span>Review sản phẩm</span>
                                    <span className="poin">1000++ điểm</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/tao-bai-viet/1/review-chuyen-gia">
                                <span className=" icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-4.svg"
                                        alt=""
                                    />
                                </span>
                                <div className=" info">
                                    <span>Review chuyên gia</span>
                                    <span className="poin">1000++ điểm</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/tao-bai-viet/1/an-choi">
                                <span className=" icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/anchoi.svg"
                                        alt=""
                                    />
                                </span>
                                <div className=" info">
                                    <span>Ăn - chơi</span>
                                    <span className="poin">500++ điểm</span>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="/tao-bai-viet/1/thao-luan">
                                <span className="icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-5.svg"
                                        alt=""
                                    />
                                </span>
                                <div className="info">
                                    <span>Thảo luận</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/tao-bai-viet/1/cho-do-cu">
                                <span className="icon">
                                    <Image
                                        width={40}
                                        height={40}
                                        src="/images/post-icon-6.svg"
                                        alt=""
                                    />
                                </span>
                                <div className="info">
                                    <span>Chợ đồ cũ</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div className="text-right">
                        <button
                            className="btn btn--blue filter-close"
                            onClick={() => setState({ showCreate: false })}
                        ></button>
                    </div>
                </div>
            </div>
        );
    };
    const [loading, setLoading] = useState(false);

    const notStickyHeader = [
        '/[...id]',
        'preview/[...id]',
        '/shop/deal-hot/landing/[...id]',
        '/happynest-story',
    ];

    if (router.asPath.includes('happynest-story')) {
        return (
            <>
                <div className="emagazine-header" style={{backgroundColor: '#222'}}>
                    <a href="/happynest-story">
                        <img
                            src="/images/emagazine/common/arrow-left.svg"
                            className="back-icon cursor-pointer"
                            alt="Happynest"
                        />
                    </a>
                    <div className="emagazine-logo">
                        <Link href="/happynest-story">
                            {/* <img className='cursor-pointer' src='/images/emagazine/emagazine-home/Frame 5920.png' width={136} /> */}
                            <span
                                style={{
                                    color: '#fff',
                                    fontSize: 20,
                                }}
                            >
                                HAPPYNEST STORY
                            </span>
                        </Link>
                    </div>
                    {genStateLogin()}
                </div>
                {(state.showSetting ||
                    state.showNoti ||
                    state.showSearch ||
                    state.showCreate) && (
                    <div
                        className="backdrop"
                        onClick={() =>
                            setState({
                                showSetting: false,
                                showNoti: false,
                                showSearch: false,
                                showCreate: false,
                            })
                        }
                    ></div>
                )}
            </>
        );
    }

    useEffect(() => {
        const setCategoriesForShop = async () => {
            const categories = await getShopCategories();
            actions.set('shopCategories', categories || []);
        };

        setCategoriesForShop();
    }, []);

    const itemListRef = useRef(null);
    const [isEndOfScroll, setIsEndOfScroll] = useState(false);
    const [isStartOfScroll, setIsStartOfScroll] = useState(true);

    const canScroll =
        itemListRef.current &&
        itemListRef.current.scrollWidth > itemListRef.current.clientWidth;
    const handleScroll = (scrollOffset) => {
        const itemList = itemListRef.current;
        if (isNumber(scrollOffset)) itemList.scrollLeft += scrollOffset;
        setIsEndOfScroll(
            itemList.scrollLeft + itemList.clientWidth >= itemList.scrollWidth,
        );
        setIsStartOfScroll(itemList.scrollLeft === 0);
    };

    useEffect(() => {
        const itemList = itemListRef?.current;
        if (!itemList) return;
        setIsEndOfScroll(
            itemList.scrollLeft + itemList.clientWidth >= itemList.scrollWidth,
        );
        setIsStartOfScroll(itemList.scrollLeft === 0);
    }, []);

    useEffect(() => {
        const fetch = async () => {
            if (!isEmpty(stateStore.consultantFilters)) return;
            const res = await http(
                `community/article/filters/consultant`,
                {},
                {},
                'v2',
            );
            if (res.success) {
                const infoData = {};
                const not_required = ["phong-cach","giai_doan_nha","phong","ngan_sach_hourse","product"];
                (res.data?.taxonomies || []).forEach((item) => {
                    const thisField = {
                        ...selectModel,
                        name: item.name,
                        slug: item.slug,
                        options: item.terms_relation.map((x) => ({
                            value: x.id,
                            label: x.name,
                            id: x.id,
                            text: x.name,
                            isadd : false,
                            products: typeof x.products === "undefined"?[]:x.products
                        })),
                        isRequired: !not_required.includes(item.slug),
                        isMultiple: item.slug === 'phong-cach',
                    };
                    infoData[item.slug] = thisField;
                });
                infoData['dich-vu'] = {
                    ...selectModel,
                    name: 'Dịch vụ',
                    slug: 'dich-vu',
                    options: (res.data?.services || []).map((x) => ({
                        value: x.id,
                        label: x.name,
                        id: x.id,
                        text: x.name,
                        isadd : false
                    })),
                    isRequired: true,
                };
                infoData['construction-time'] = {
                    ...selectModel,
                    name: 'Thời gian thi công',
                    slug: 'construction-time',
                    options: (res.data?.construction_time || []).map((x) => ({
                        value: x.id,
                        label: x.name,
                        id: x.id,
                        text: x.name,
                        isadd : false
                    })),
                    isRequired: true,
                };
                infoData['hang-muc'] = {
                    ...selectModel,
                    name: 'Hạng mục',
                    slug: 'hang-muc',
                    options: (res.data?.service_items || []).map((x) => ({
                        value: x.id,
                        label: x.name,
                        id: x.id,
                        text: x.name,
                        isadd : false
                    })),
                    isRequired: true,
                };
                actions.set('consultantFilters', { ...infoData, ...res.data });
            }
        };
        fetch();
    }, []);

    useEffect(() => {
        try {
            window.insider_object = window.insider_object || {};
            if (isEmpty(stateStore.profileCommunity)) {
                window.insider_object.user = {};
                return;
            }

            const user = stateStore.profileCommunity || {};
            window.insider_object.user = {
                uuid: `${user.id || ''}`,
                gender: `${
                    user.gender_id ? (user.gender_id == 2 ? 'F' : 'M') : ''
                }`,
                birthday: `${user.birthday || ''}`,
                name: `${user.fullname || ''}`,
                email: `${user.email || ''}`,
                phone_number: `${user.phone_number || ''}`,
            };
        } catch (e) {
            console.log(e);
        }
    }, [stateStore.profileCommunity]);

    //TODO display header of lam nha
    return (
        <>
            {modalLogout()}
            <Loader loading={loading} />
            <div id="header-switch-version">
                <div className="max-1320px switch-version">
                    <SliderAd
                        positionDesktop="26"
                        pageDesktop="61"
                        positionMobile="27"
                        pageMobile="62"
                    />
                </div>
            </div>
            <header
                className={
                    'header ' +
                    (notStickyHeader.some((x) => router.pathname.includes(x))
                        ? 'not-sticky-header'
                        : '')
                }
            >
                <div className="headerTop">
                    <div className="wrap">
                        <div className="headerTop-dflex">
                            <div className="headerInfo">
                                {
                                    // (router.pathname.includes("/shop"))
                                    //     ? <div className="btnNav">
                                    //         <button type="button" className="btn btn--nav">
                                    //             <span></span>
                                    //             <span></span>
                                    //             <span></span>
                                    //         </button>
                                    //     </div>
                                    //     :
                                    <div className="logo sp-only">
                                        <a href="/">
                                            <img
                                                width={109}
                                                height={25}
                                                src="/images/logo.svg"
                                                alt="Happynest"
                                            />
                                        </a>
                                    </div>
                                }
                                <div className="logo">
                                    <a href="/">
                                        <img
                                            width={140}
                                            height={32}
                                            src="/images/logo.svg"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="gNav">
                                    <ul>{genMasterMenuData}</ul>
                                </div>
                            </div>
                            <div className="headerControl">
                                <SearchArea state={state} setState={setState} />
                                {genStateLogin()}
                            </div>
                        </div>
                    </div>
                </div>
                {(state.showSetting ||
                    state.showNoti ||
                    state.showSearch ||
                    state.showCreate) && (
                    <div
                        className="backdrop"
                        onClick={() =>
                            setState({
                                showSetting: false,
                                showNoti: false,
                                showSearch: false,
                                showCreate: false,
                            })
                        }
                    ></div>
                )}
                {!(
                    router.pathname.includes('/chuyen-gia') ||
                    router.pathname.includes('/thanh-vien/u') ||
                    router.pathname.includes('tim-kiem') ||
                    router.pathname.includes('thong-tin') ||
                    router.pathname.includes('ho-so/') ||
                    router.pathname.includes('dieu-khoan-chinh-sach') ||
                    router.pathname.includes('faq') ||
                    router.pathname.includes('trung-tam-tro-giup') ||
                    router.pathname.includes('gioi-thieu') ||
                    router.pathname.includes('shop/profile') ||
                    router.pathname.includes('preview')
                ) &&
                    !isEmpty(genNav()) && (
                    <div className="headerNav">
                        <div className="wrap">
                            <div className="headerMain">
                                <div className="navigation position-relative">
                                    {router.pathname.split('/')[1] ===
                                        'tai-khoan' ? (
                                            <ul
                                                className="nav nav-padding-normal"
                                                ref={itemListRef}
                                                style={{
                                                    display: 'flex',
                                                    lineHeight: '48px',
                                                    justifyContent: 'center',
                                                    fontSize: '16px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {genNav()}
                                            </ul>
                                        ) : (
                                            <ul
                                                ref={itemListRef}
                                                onScroll={handleScroll}
                                                style={{
                                                    scrollBehavior: 'smooth',
                                                }}
                                            >
                                                {genNav()}
                                            </ul>
                                        )}
                                    {canScroll && !isEndOfScroll && (
                                        <div
                                            className="position-absolute cursor-pointer pc-only"
                                            onClick={() =>
                                                handleScroll(100)
                                            }
                                            style={{
                                                display: 'flex',
                                                right: 12,
                                                top: 0,
                                                background:
                                                        'linear-gradient(270deg, #FFFFFF 57.21%, rgba(255, 255, 255, 0) 155.64%)',
                                                height: '100%',
                                                width: '48px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img src="/images/icon-arrow-slider.svg" />
                                        </div>
                                    )}
                                    {canScroll && !isStartOfScroll && (
                                        <div
                                            className="position-absolute cursor-pointer pc-only"
                                            onClick={() =>
                                                handleScroll(-100)
                                            }
                                            style={{
                                                display: 'flex',
                                                left: -12,
                                                top: 0,
                                                background:
                                                        'linear-gradient(90deg, #FFFFFF 57.21%, rgba(255, 255, 255, 0) 155.64%)',
                                                height: '100%',
                                                width: '48px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src="/images/icon-arrow-slider.svg"
                                                style={{
                                                    transform:
                                                            'rotate(180deg)',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </header>
            {/* {!router.pathname.includes("shop") ? genBtnEdit() : <></>} */}
            {router.pathname != '/[...id]' &&
                router.pathname != '/preview/[...id]' && (
                <div className="navFooter" style={{ zIndex: 1111 }}>
                    <ul>
                        <li
                            className={
                                router.pathname === '/' ||
                                    state.menusCongDong.some(
                                        (menu) =>
                                            router.asPath.indexOf(
                                                '/' + menu.slug,
                                            ) == 0,
                                    )
                                    ? ' active'
                                    : ''
                            }
                        >
                            <a href="/">
                                <div className="navFooter-item">
                                    <img
                                        className="svg"
                                        src={
                                            router.pathname === '/' ||
                                                state.menusCongDong.some(
                                                    (menu) =>
                                                        router.asPath.indexOf(
                                                            '/' + menu.slug,
                                                        ) == 0,
                                                )
                                                ? ' /images/icon-community-active.svg'
                                                : '/images/icon-community.svg'
                                        }
                                        alt=""
                                    />
                                </div>
                                    Cộng đồng
                            </a>
                        </li>
                        <li
                            className={
                                router.pathname.indexOf('/shop') === 0
                                    ? ' active'
                                    : ''
                            }
                        >
                            <a href="/shop">
                                <div className="navFooter-item">
                                    <img
                                        className="svg"
                                        src={
                                            router.pathname.indexOf(
                                                '/shop',
                                            ) === 0
                                                ? '/images/icon-store-active.svg'
                                                : '/images/icon-store.svg'
                                        }
                                        alt=""
                                    />
                                </div>
                                    Shop
                            </a>
                        </li>
                        <li
                            className={
                                router.pathname.indexOf('/chuyen-gia') ===
                                        0 ||
                                    router.pathname.indexOf('/thanh-vien') === 0
                                    ? ' active'
                                    : ''
                            }
                        >
                            <a href="/chuyen-gia">
                                <div className="navFooter-item">
                                    <img
                                        className="svg"
                                        src={
                                            router.pathname.indexOf(
                                                '/chuyen-gia',
                                            ) === 0 ||
                                                router.pathname.indexOf(
                                                    '/thanh-vien',
                                                ) === 0
                                                ? '/images/icon-expert-active.svg'
                                                : '/images/icon-expert.svg'
                                        }
                                        alt=""
                                    />
                                </div>
                                    Chuyên gia
                            </a>
                        </li>
                        <li
                            className={
                                router.pathname.indexOf(
                                    '/tai-khoan/thong-bao',
                                ) === 0
                                    ? ' active'
                                    : ''
                            }
                        >
                            <a href="/tai-khoan/thong-bao">
                                <div className="navFooter-item">
                                    <img
                                        className="svg"
                                        src={
                                            router.pathname.indexOf(
                                                '/tai-khoan/thong-bao',
                                            ) === 0
                                                ? '/images/icon-notification-active.svg'
                                                : '/images/icon-notification.svg'
                                        }
                                        alt=""
                                    />
                                </div>
                                    Thông báo
                            </a>
                        </li>
                        <li
                            className={
                                router.pathname.indexOf('/tai-khoan') ===
                                        0 &&
                                    router.pathname.indexOf(
                                        '/tai-khoan/thong-bao',
                                    ) !== 0
                                    ? ' active'
                                    : ''
                            }
                        >
                            <a
                                href={
                                    isEmpty(getCookie(TOKEN_CONGDONG))
                                        ? `/login`
                                        : `/tai-khoan/ho-so`
                                }
                            >
                                <div className="navFooter-item">
                                    <img
                                        className="svg"
                                        src={
                                            router.pathname.indexOf(
                                                '/tai-khoan',
                                            ) === 0 &&
                                                router.pathname.indexOf(
                                                    '/tai-khoan/thong-bao',
                                                ) !== 0
                                                ? '/images/icon-profile-active.svg'
                                                : '/images/icon-profile.svg'
                                        }
                                        alt=""
                                    />
                                </div>
                                    Tài khoản
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export const BtnDangBai = () => {
    const [state, setState] = useState({
        showCreate: false,
    });

    return (
        <div
            className=" btnDangBai"
            // onClick={() => router.push('/cong-dong/tao-bai-viet/chuyen-nha')} style={{cursor: 'pointer'}}
        >
            <div style={{ cursor: 'pointer', width: '100%', height: 40 }}>
                <img
                    onClick={() => {
                        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
                            toast.info(
                                'Bạn cần đăng nhập để viết bài trên Happynest!',
                            );
                            return;
                        }

                        setState({ showCreate: !state.showCreate });
                    }}
                    className="img-dang-bai"
                    src="/images/btn-edit-bg.png"
                ></img>
            </div>
            <div
                className={`dropdown-menu dropdown-menu-right ${
                    state.showCreate ? 'd-block' : 'd-none'
                }`}
            >
                <h3 className=" title">Tạo bài viết về</h3>
                <ul
                    className=" list-post"
                    onClick={() => setState({ showCreate: false })}
                >
                    <li>
                        <a href="/tao-bai-viet/1/chuyen-nha">
                            <span className=" icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-1.svg"
                                    alt=""
                                />
                            </span>
                            <div className=" info">
                                <span>Chuyện nhà</span>
                                <span className="poin">1000++ điểm</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/tao-bai-viet/1/album">
                            <span className=" icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-2.svg"
                                    alt=""
                                />
                            </span>
                            <div className=" info">
                                <span>Kho ảnh</span>
                                <span className="poin">500++ điểm</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/tao-bai-viet/1/review-san-pham">
                            <span className=" icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-3.svg"
                                    alt=""
                                />
                            </span>
                            <div className=" info">
                                <span>Review sản phẩm</span>
                                <span className="poin">1000++ điểm</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/tao-bai-viet/1/review-chuyen-gia">
                            <span className=" icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-4.svg"
                                    alt=""
                                />
                            </span>
                            <div className=" info">
                                <span>Review chuyên gia</span>
                                <span className="poin">1000++ điểm</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/tao-bai-viet/1/an-choi">
                            <span className=" icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/anchoi.svg"
                                    alt=""
                                />
                            </span>
                            <div className=" info">
                                <span>Ăn - chơi</span>
                                <span className="poin">500++ điểm</span>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a href="/tao-bai-viet/1/thao-luan">
                            <span className="icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-5.svg"
                                    alt=""
                                />
                            </span>
                            <div className="info">
                                <span>Thảo luận</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/tao-bai-viet/1/cho-do-cu">
                            <span className="icon">
                                <Image
                                    width={40}
                                    height={40}
                                    src="/images/post-icon-6.svg"
                                    alt=""
                                />
                            </span>
                            <div className="info">
                                <span>Chợ đồ cũ</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className="text-right">
                    <button
                        className="btn btn--blue filter-close"
                        onClick={() => setState({ showCreate: false })}
                    ></button>
                </div>
            </div>
        </div>
    );
};
