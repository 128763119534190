/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import Image from 'next/image';

export default function FooterHome() {
    return <footer className="footer">
        <div className="wrap">
            <div className="logo"><a href="#"><Image width={140} height={32} src="/images/logo.svg" alt=""/></a></div>
            <div className="footerWrap">
                <div className="footerCompany">
                    <div className="company">
                        <h3 className="company-name">CÔNG TY CỔ PHẦN THƯƠNG MẠI & TRUYỀN THÔNG HAPPYNEST</h3>
                        <div className="company-info"><span className="item"><img width={12} height={14}  src="/images/icon-maps.svg"
                            alt=""/></span>Số 97 Hoàng Ngân, phường Nhân Chính, quận Thanh Xuân, Thành phố Hà Nội</div>
                        <div className="company-info"><span className="item"><img width={12} height={14} src="/images/icon-maps.svg"
                            alt=""/></span>VPGD: số 6 Nguyễn Công Hoan, phường Ngọc Khánh, quận Ba Đình, Thành phố Hà Nội</div>
                        <div className="company-info"><span className="item"><img width={14} height={14}  src="/images/icon-hotline.svg"
                            alt=""/></span><span
                            className="pr-3">024 2280 6688</span><span>Hotline: 0934 680 636</span></div>
                        <div className="company-info"><span className="item"><img width={14} height={12}  src="/images/icon-email.svg"
                            alt=""/></span><a
                            href="#">info@happynest.vn</a></div>
                    </div>
                    <div className="footer-papers">
                        <p className="footer-text">Mã số doanh nghiệp: 0109479528<br /><span>Đăng ký lần đầu: ngày 31 tháng 12 năm 2020</span><br />
                            <span>Nơi cấp: Sở Kế Hoạch và Đầu Tư Thành Phố Hà Nội
                            </span></p>
                        <p className="footer-text">Giấy phép
                            MXH:<br /><span>231/GP-BTTTT do BTTTT cấp ngày 10/05/2022</span></p>
                        <p className="footer-text">Chịu trách nhiệm nội dung:<br/><span>Bà Cao Tuyết Minh</span></p>
                        {/* <div className="footer-text"> */}
                        <a href="//www.dmca.com/Protection/Status.aspx?ID=fe6df368-5978-41ac-9602-93ed1a122e8d" title="DMCA.com Protection Status" className="dmca-badge">
                            <img src ="https://images.dmca.com/Badges/dmca-badge-w100-5x1-07.png?ID=fe6df368-5978-41ac-9602-93ed1a122e8d"  alt="DMCA.com Protection Status" width={150}/>
                        </a>
                        {/* </div> */}
                    </div>
                </div>
                <div className="footerInfo">
                    <div className="footerWrap">
                        <div className="footerNav">
                            <ul className="footerNavigation">
                                <li><a href="/gioi-thieu">Giới thiệu Happynest</a></li>
                                <li><a href="/dieu-khoan-chinh-sach">Điều khoản và chính sách</a></li>
                                <li><a href="/faq">Câu hỏi thường gặp</a></li>
                                <li><a href="/trung-tam-tro-giup">Trung tâm trợ giúp</a></li>
                                <li><a target="_blank" rel="noreferrer"
                                    href="https://docs.google.com/forms/d/1Q7yBN61DL0Hcvst9OKqw_DWH4AJ10ksBJNcJhpZmBEE">Đánh
                                    giá</a></li>
                                <li><a href="/tai-khoan/cai-dat">Đăng ký làm chuyên gia</a></li>
                                <li><a href="/trung-tam-tro-giup/100000064/ban-hang-cung-happynest">Đăng ký bán hàng</a>
                                </li>
                                <li className="pc-only"><a href="http://online.gov.vn/Home/WebDetails/86743">
                                    <img alt="" title="" width="154px" src="/images/logoCCDV.jpeg"/>
                                </a></li>
                                <li className="h-97px">
                                    <script language="JavaScript" src="https://dunsregistered.dnb.com"
                                        type="text/javascript"></script>
                                </li>
                            </ul>
                        </div>
                        <div className="footerIntrol">
                            <div className="signForm pc-only">
                                <h3 className="signForm-title">Đăng ký nhận tin</h3>
                                <div className="signForm-input">
                                    <input className="form-control" type="text" placeholder="Email của bạn"/>
                                    <button className="btn btn--blue"><span>
                                        <Image width={20} height={20}  src="/images/icon-send.svg" alt=""/></span>Gửi
                                    </button>
                                </div>
                            </div>
                            <div className="footerShare pc-only">
                                <h3 className="footerShare-title">Tải app Happynest tại</h3>
                                <ul className="footerShare-app">
                                    <li><a href="https://apps.apple.com/vn/app/happynest/id1581494990?l=vi"><Image width={136} height={40}  src="/images/appIos.png" alt=""/></a></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=vn.happynest.home&fbclid=IwAR2gsyX8hDNtPLQqdiJ3RRmDaxSSLwzsOKlzs7jX4ip7_3ZCtVhJa3Y2eoE"><Image width={136} height={40}  src="/images/appAndroi.png" alt=""/></a></li>
                                </ul>
                                <ul className="footerShare-item">
                                    <li><a href="https://www.facebook.com/happynestvn"><Image width={32} height={32}  src="/images/Facebook.svg" alt=""/></a></li>
                                    <li><a href="https://www.instagram.com/happynest.vn/"><Image width={32} height={32}  src="/images/Instagram.svg" alt=""/></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCmpa_rk-zhBYJCDM_hdu2-A"><Image width={32} height={32}  src="/images/Youtube.svg" alt=""/></a></li>
                                    <li><a href="https://vt.tiktok.com/ZSe2q2mtS/"><Image width={32} height={32}  src="/images/Tiktok.svg" alt=""/></a></li>
                                </ul>
                            </div>
                            <div className="footerShare sp-only mt-0">
                                <h3 className="footerShare-title">Tải app Happynest tại</h3>
                                <ul className="footerShare-app">
                                    <li><a href="https://apps.apple.com/vn/app/happynest/id1581494990?l=vi"><Image width={136} height={40}  src="/images/appIos.png" alt=""/></a></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=vn.happynest.home&fbclid=IwAR2gsyX8hDNtPLQqdiJ3RRmDaxSSLwzsOKlzs7jX4ip7_3ZCtVhJa3Y2eoE"><Image width={136} height={40}  src="/images/appAndroi.png" alt=""/></a></li>
                                </ul>
                                <ul className="footerShare-item">
                                    <li><a href="https://www.facebook.com/happynestvn"><Image width={32} height={32}  src="/images/Facebook.svg" alt=""/></a></li>
                                    <li><a href="https://www.instagram.com/happynest.vn/"><Image width={32} height={32}  src="/images/Instagram.svg" alt=""/></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCmpa_rk-zhBYJCDM_hdu2-A"><Image width={32} height={32}  src="/images/Youtube.svg" alt=""/></a></li>
                                    <li><a href="https://vt.tiktok.com/ZSe2q2mtS/"><Image width={32} height={32}  src="/images/Tiktok.svg" alt=""/></a></li>
                                    <li className="sp-only mt-3" style={{width: '100%'}}><a href='http://online.gov.vn/Home/WebDetails/86743'>
                                        <img alt='' title='' width='136px' src='/images/logoCCDV.jpeg' />
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="signForm sp-only mt-3" style={{ width: '100%', paddingLeft: '5%' }}>
                            <h3 className="signForm-title">Đăng ký nhận tin</h3>
                            <div className="signForm-input">
                                <input className="form-control" type="text" placeholder="Email của bạn" />
                                <button className="btn btn--blue"><span>
                                    <Image width={20} height={20} src="/images/icon-send.svg" alt="" /></span>Gửi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <address className="copyright">© 2021 Happynest</address>
    </footer>;
};